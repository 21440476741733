import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variant as styledVariant } from 'styled-system';
import css from '@styled-system/css';

import { track } from 'utils/analytics';
import { colors, gradients } from 'utils/theme';
import Section from 'legacy/components/section';
import Box from 'legacy/components/box';
import Heading from 'legacy/components/heading';
import Text from 'legacy/components/text';
import Button from 'legacy/components/button';
import Card from 'legacy/components/card';
import Flex from 'legacy/components/flex';

const StyledSection = styled(Section)(
  styledVariant({
    prop: 'bgVariant',
    variants: {
      white: {
        bg: 'white',
      },
      blue: {
        bg: 'blue',
      },
      gray: {
        bg: 'gray100',
      },
      graySecondary: {
        bg: 'gray100',
      },
      embedded: {
        p: 4,
        backgroundImage: `radial-gradient(557.42% 227.15% at -333.4% 10%, rgba(95, 117, 148, 0) 0%, ${colors.blue} 80%),
          radial-gradient(circle at 30% -260%, ${colors.blue50} 0%, ${colors.blue50} 80%, ${colors.blue} 80%)`,
        backgroundPosition: 'center, center',
        borderRadius: 'large',
      },
      compactDark: {
        bg: 'transparent',
      },
      compactLight: {
        bg: 'transparent',
      },
      compactGradientBlue: {
        bg: 'transparent',
      },
      compactGradientTeal: {
        bg: 'transparent',
      },
    },
  })
);

const StyledCard = styled(Card)(
  styledVariant({
    variants: {
      compactDark: {
        bg: 'blue',
        p: 5,
      },
      compactLight: {
        bg: 'gray100',
        p: 5,
      },
      compactGradientBlue: {
        background: gradients.blueBlue,
        mx: ['-1.5rem', null, null, 0],
        p: 4,
      },
      compactGradientTeal: {
        bg: 'teal40',
        mx: ['-1.5rem', null, null, 0],
        p: 4,
      },
    },
  })
);

const StyledHeading = styled(Heading)(
  css({
    fontSize: [5, null, null, 6],
  }),
  styledVariant({
    prop: 'styleVariant',
    variants: {
      white: {
        color: 'blue',
      },
      blue: {
        color: 'white',
      },
      gray: {
        color: 'blue',
      },
      graySecondary: {
        color: 'blue',
      },
      embedded: {
        fontSize: [4, null, null, 4],
        color: 'white',
      },
    },
  })
);

const StyledText = styled(Text)(
  css({
    mt: 4,
    fontSize: [2, null, null, 3],
  }),
  styledVariant({
    prop: 'styleVariant',
    variants: {
      white: {
        color: 'text',
      },
      blue: {
        color: 'white',
      },
      gray: {
        color: 'text',
      },
      graySecondary: {
        color: 'text',
      },
      embedded: {
        mt: 3,
        fontSize: [1, null, null, 1],
        color: 'white',
      },
      compactDark: {
        fontSize: [2, null, null, 4],
        color: 'white',
      },
      compactLight: {
        fontSize: [2, null, null, 4],
        color: 'blue',
      },
      compactGradientBlue: {
        mt: 0,
        fontSize: 2,
        color: 'white',
        '& + h2': {
          mt: 3,
        },
      },
      compactGradientTeal: {
        mt: 0,
        fontSize: 2,
        color: 'white',
        '& + h2': {
          mt: 3,
        },
      },
    },
  })
);

const CompactContainer = styled(Flex)(
  css({
    flexWrap: 'wrap',
    alignItems: 'center',
  }),
  styledVariant({
    variants: {
      compactDark: {
        mx: [0, null, -5, -6],
      },
      compactLight: {
        mx: [0, null, -5, -6],
      },
      compactGradientBlue: {
        mx: [0, null, -3],
      },
    },
  })
);

const CompactCopyWrapper = styled(Box)(
  css({
    width: ['100%', null, '62%'],
  }),
  styledVariant({
    variants: {
      compactDark: {
        px: [0, null, 5, 6],
      },
      compactLight: {
        px: [0, null, 5, 6],
      },
      compactGradientBlue: {
        px: [0, null, 3],
      },
      compactGradientTeal: {
        px: [0, null, 3],
      },
    },
  })
);

const CompactCtaWrapper = styled(Box)(
  css({
    mt: [3, null, 0],
    width: ['100%', null, '38%'],
  }),
  styledVariant({
    variants: {
      compactDark: {
        px: [0, null, 5, 6],
      },
      compactLight: {
        px: [0, null, 5, 6],
      },
      compactGradientBlue: {
        px: [0, null, 3],
      },
      compactGradientTeal: {
        px: [0, null, 3],
      },
    },
  })
);

const CtaSection = ({
  size,
  title,
  content,
  buttonText,
  link,
  variant,
  hideCta,
  children,
  ...props
}) => {
  const ctaVariants = {
    white: 'secondary',
    blue: 'secondary',
    gray: 'primary',
    graySecondary: 'secondary',
    embedded: 'secondary',
    compactDark: 'secondaryGradient',
    compactLight: 'secondaryGradient',
    compactGradientBlue: 'secondary',
    compactGradientTeal: 'primary',
  };

  const handleCtaClick = () => {
    track('cta_click', {
      cta_type: 'banner',
      cta_text: buttonText,
      cta_url: link,
    });
  };

  const handleCtaDown = (e) => {
    if (e.button > 0) {
      let mouseButton;

      switch (e.button) {
        case 1:
          mouseButton = 'middle_mouse';
          break;
        case 2:
          mouseButton = 'right_mouse';
          break;
        default:
          mouseButton = 'mouse';
      }

      track('cta_click', {
        cta_type: 'banner',
        cta_text: buttonText,
        cta_url: link,
        mouse_button: mouseButton,
      });
    }
  };

  return (
    <StyledSection variant={size} bgVariant={variant} {...props}>
      <Section.Inner>
        {!variant.includes('compact') && (
          <>
            <Box maxWidth="48rem" mx="auto" textAlign="center">
              <StyledHeading styleVariant={variant}>{title}</StyledHeading>
              {!!content?.length && (
                <StyledText
                  styleVariant={variant}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
            </Box>
            {children}
            {!hideCta && (
              <Box mx="auto" maxWidth={208}>
                <Button
                  as="a"
                  href={link}
                  onClick={handleCtaClick}
                  onMouseDown={handleCtaDown}
                  // don't love this, but the alternative is recreating all the button
                  // styles here, which I like even less
                  variant={ctaVariants[variant]}
                  btnSize="lg"
                  width={1}
                  // see above
                  mt={variant === 'embedded' ? 3 : 5}
                >
                  {buttonText}
                </Button>
              </Box>
            )}
          </>
        )}
        {variant.includes('compact') && (
          <StyledCard variant={variant}>
            <CompactContainer variant={variant}>
              <CompactCopyWrapper variant={variant}>
                {!!title?.length && (
                  <Heading
                    variant={['h4', null, 'h3']}
                    color={variant === 'compactDark' ? 'white' : 'blue'}
                  >
                    {title}
                  </Heading>
                )}
                <StyledText
                  styleVariant={variant}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </CompactCopyWrapper>
              <CompactCtaWrapper variant={variant}>
                <Box p={1}>
                  <Button
                    as="a"
                    href={link}
                    onClick={handleCtaClick}
                    onMouseDown={handleCtaDown}
                    variant={ctaVariants[variant]}
                    btnSize={
                      variant.includes('compactGradient') ? ['md', null, 'lg'] : 'xxl'
                    }
                    width={1}
                  >
                    {buttonText}
                  </Button>
                </Box>
              </CompactCtaWrapper>
            </CompactContainer>
          </StyledCard>
        )}
      </Section.Inner>
    </StyledSection>
  );
};

CtaSection.propTypes = {
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  variant: PropTypes.oneOf([
    'blue',
    'gray',
    'graySecondary',
    'embedded',
    'compactDark',
    'compactLight',
    'compactGradientBlue',
    'compactGradientTeal',
  ]),
  hideCta: PropTypes.bool,
  borderRadius: PropTypes.string,
  children: PropTypes.node,
};

CtaSection.defaultProps = {
  size: 'large',
  content: '',
  buttonText: 'Get Started',
  link: '/signup/fast-track',
  variant: 'blue',
  hideCta: false,
  borderRadius: '',
  children: null,
};

export default CtaSection;
